import {Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SplitGrantsSettings, Settings } from '../../../../../core/models';

@Component({
  selector: 'app-split-grants-settings-dialog',
  templateUrl: './split-grants-settings-dialog.component.html',
  styleUrls: ['./split-grants-settings-dialog.component.scss']
})
export class SplitGrantsSettingsDialogComponent implements OnInit {

  settings: SplitGrantsSettings;

  form: FormGroup = new FormGroup({
    lock_before_notify: new FormControl<boolean>(true)
  });

  constructor(public dialogRef: MatDialogRef<SplitGrantsSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Settings) { }

  ngOnInit(): void {
    this.settings = this.data['split-grants'] || { lock_before_notify: false };
    this.form.patchValue(this.settings);
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

}
